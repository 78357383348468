.@{switch-prefix-cls} {
    background-color: @switch-container-bg;
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px fade(@switch-container-bg, 10%);
      }
    
      &-checked:focus {
        box-shadow: 0 0 0 2px @primary-1;
      }
    
      &:focus:hover {
        box-shadow: none;
      }
    
      &-checked {
        background-color: @switch-color;
      }
    
      &-loading,
      &-disabled {
        cursor: not-allowed;
        opacity: @switch-disabled-opacity;
    
        * {
          box-shadow: none;
          cursor: not-allowed;
        }
      }
}
