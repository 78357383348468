@cards: primary, secondary, success,
    warning, danger, info, light,dark;

.getCards(@index:1) when(@index <= length(@cards)) {
  @name: extract(extract(@cards, @index), 1);
  @color: ~'@{@{name}-color}';
  .@{card-prefix-cls} {
    &.@{card-prefix-cls} {
      &-@{name} {
        background-color: @color;
        color: #fff;
        .ant-card-head{
          color: #fff;
        }
      }
      &-border-@{name} {
        border:1px solid @color;
        .ant-card-head{
          border-bottom:1px solid @color;
          color: @color;
        }
      }
    }
  }

  .getCards(@index + 1);
}

.getCards();


.@{card-prefix-cls} {

  .fixed-card{
    display: flex;
    flex-direction: column;
    .@{card-prefix-cls}{
      &-head{
        position: absolute;
         border: 0px;
         padding: 0px 2px 0px 10px;
         z-index: 10;
      }
      &-cover{
        width: 100%;
        flex: auto;
        height: 250px;
        > img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-body{
        flex: 4;
      }
    }
    &-description {
      height: 70px;
    }
    &-short-description {
      height: 20px;
    }
    &-section-1-2 {
      height: 23px;
    }
    &-section-3-actions {
      height: 40px;
    }

    &.list-item {
      flex-direction: row;
      .@{card-prefix-cls}{
        &-cover{
          flex: 1;
          height: auto;
          width: auto;
        }
        &-body{
          flex: 4;
        }
      }
    }
  }


}
