.@{pagination-prefix-cls} {
    &-item {
        border: @pagination-item-border;
        a {
            color: @pagination-item-color;
        }

        &:hover {
            border-color: @pagination-item-bg-active;
            a {
                color: @pagination-item-bg-active;
            }
        }
        &:focus-visible {
            border-color: @pagination-item-bg-active;
            a {
                color: @pagination-item-bg-active;
            }
        }
        &-active {
            a {
                color: @pagination-item-color-active;
                &:hover {
                    border-color: @pagination-item-color-active;
                }
            }

            &:focus-visible {
                border-color: @pagination-item-color-active;
            }

            &:hover a {
                color: @pagination-item-color-active;
            }

            &:focus-visible a {
                color: @pagination-item-color-active;
            }
        }
    }

    &-prev,
    &-next {
        button {
            color: @pagination-item-arrow-color;
            background-color: @pagination-item-arrow-bg;
        }

        &:hover button {
            border-color: @pagination-item-bg-active;
            background-color: @pagination-item-bg-active;
            color: @pagination-item-color-active;
        }

        .@{pagination-prefix-cls}-item-link {
            border: @pagination-item-border;
            color: @pagination-item-arrow-color;
            background-color: @pagination-item-arrow-bg;
        }

        &:focus-visible .@{pagination-prefix-cls}-item-link {
            background-color: @pagination-item-bg-active;
            color: @pagination-item-color-active;
            border-color: @pagination-item-bg-active;
        }

        &:hover .@{pagination-prefix-cls}-item-link {
            background-color: @pagination-item-bg-active;
            color: @pagination-item-color-active;
            border-color: @pagination-item-bg-active;
        }
    }
}


