.@{menu-prefix-cls} {
  &-item-selected,
  &-submenu-selected {
    .@{menu-prefix-cls}-item-icon,
    .@{iconfont-css-prefix} {
      color: @menu-item-active-icon-color;
    }
  }

  &-vertical,
  &-vertical-left,
  &-vertical-right,
  &-inline {
    .@{menu-prefix-cls}-item {
      &::after {
        right: auto;
        left: 0;
        border-right: @menu-item-active-border-width solid
          @menu-highlight-border-color;
        border-radius: 0 10px 10px 0;
      }
    }
  }

  &-item-group-title {
    position: relative;
    margin: 0 16px 0 24px;
    padding-left: 0;
    &:before {
      content: "";
      background-color: @border-color-split;
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      display: block;
    }
    > span {
      background-color: @layout-header-background;
      position: relative;
      z-index: 1;
      padding-right: 5px;
    }
  }

  &-horizontal {
    border: 0;

    &:not(.@{menu-prefix-cls}-dark) {
      > .@{menu-prefix-cls}-item,
      > .@{menu-prefix-cls}-submenu {
        &:hover,
        &-active,
        &-open,
        &-selected {
          &::after {
            border-bottom: 0;
          }
        }
      }
    }

    > .@{menu-prefix-cls}-item,
    > .@{menu-prefix-cls}-submenu {
      &::after {
        display: none;
      }
    }
  }

  &-title-content {
    display: flex;
    svg {
      min-width: 14px;
      font-size: @menu-icon-size;
    }
  }
}

[dir="rtl"] {
  .@{menu-prefix-cls} {
    &-item-group-title {
      margin: 0 24px 0 16px;
      padding-left: 16px;
      padding-right: 0;
      > span {
        padding-right: 0;
        padding-left: 5px;
      }
    }

    &-vertical,
    &-vertical-left,
    &-vertical-right,
    &-inline {
      .@{menu-prefix-cls}-item {
        &::after {
          right: 0;
          left: auto;
          border-radius: 10px 0 0 10px;
        }
      }
    }
  }
}
