.@{dropdown-prefix-cls} {
    &-menu {
        &-item-icon {
            font-size: @dropdown-menu-item-icon-size;
            color: @dropdown-menu-item-icon-color;
        }
    }
}

/* User Dropdown */

.user-dropdown {
    display: flex;
    align-items: center;
    .ant-avatar {
        margin-right: 10px;
    }
    h4 {
        margin-bottom: 0;
    }
    p {
        margin: 0;
        font-size: 0.88em;
        color: @text-color-secondary;
    }
    svg {
        margin-left: auto;
    }
}

[dir="rtl"] {
    .user-dropdown {
        display: flex;
        align-items: center;
        .ant-avatar {
            margin-right: 0;
            margin-left: 10px;
        }
        svg {
            margin-left: 0;
            margin-right: auto;
        }
    }
}
