.@{notification-prefix-cls} {
  &-notice {
    &-success {
      &-icon {
        svg {
          color: @success-color;
        }
      }
    }

    &-info {
      &-icon {
        svg {
          color: @info-color;
        }
      }
    }

    &-warning {
      &-icon {
        svg {
          color: @warning-color;
        }
      }
    }

    &-error {
      &-icon {
        svg {
          color: @error-color;
        }
      }
    }
  }
}
