.@{tag-prefix-cls} {
    line-height: 19px;
    > svg {
        font-size: 1.1em;
        margin-bottom: -2px;
    }
    > svg + span,
    > span + svg {
        margin-left: 4px;
    }
}

[dir="rtl"] {
    .@{tag-prefix-cls} {
        > svg + span,
        > span + svg {
            margin-right: 4px;
            margin-left: 0;
        }
    }
}
