.@{segmented-prefix-cls} {
  &-item {
    &-icon {
      svg {
        font-size: 1.2em;
        position: relative;
        top: 3px;
      }
    }
  }
}
