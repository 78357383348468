.@{upload-prefix-cls}{
    /* List */
    &-list {
        &-item {
            border-top: 1px solid @border-color-base;
            padding-top: @padding-xs;
            height: auto;
            &:hover &-info {
                background-color: transparent;
            }

            .@{iconfont-css-prefix}-close {
                opacity: 1;
            }

            &-card-actions-btn {
                opacity: 1;
            }
        }
        &-text {
            &-container {
                &:first-child {
                    .@{upload-prefix-cls}-list-item {
                        border-top: 0;
                    }
                }
            }
        }
    }
    
    /* Drag & Drop */
    &&-drag {
        background: transparent;
        p.@{upload-prefix-cls}-drag-icon {
            svg {
                color: @primary-5;
                font-size: 48px;
            }
        }
    }

    /* Picture Card */
    &-list{
        &-picture-card {
            .@{upload-item} {
                padding: 0;
                overflow: hidden;
            }

            .@{upload-item}-actions {
                display: flex;
            }

            .@{upload-item}-thumbnail,
            .@{upload-item}-thumbnail img {
                object-fit: cover;
                .@{iconfont-css-prefix} {
                    margin-top: 25px;
                  }
            }

            .@{upload-item}-file + .@{upload-item}-name {
                bottom: 20px;
            }

            .@{upload-item}-progress {
                width: 100%;
                padding: 0 14px;
              }
        }
    }
}