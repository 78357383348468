.@{tab-prefix-cls}{
    /* Line Tabs */

    .ant-tabs-nav{
        .ant-tabs-nav-list{
            .ant-tabs-tab{
                .ant-tabs-tab-btn{
                    color: @text-color-secondary;
                    > svg {
                        height: 21px;
                        width: 21px;
                        margin-bottom: -6px;
                    }
                }
                &.ant-tabs-tab-active{
                    .ant-tabs-tab-btn{
                        color: @tabs-card-active-color;
                    }
                }
            }
        }
    }

    /* Card Tabs */
    &-card{
        .ant-tabs-nav{
            &::before{
                border: 0;
            }
            .ant-tabs-nav-list{
                background-color: @tabs-card-tab-list-background;
                padding: @tabs-card-tab-list-padding;
                border-radius: @tabs-card-tab-list-border-radius;
                .ant-tabs-tab{
                    border-radius: @tabs-card-tab-list-border-radius !important;
                    border: 0;
                    .ant-tabs-tab-btn{
                        color: @text-color-secondary;
                    }
                    &.ant-tabs-tab-active{
                        .ant-tabs-tab-btn{
                            color: @tabs-card-active-color;
                        }
                    }
                }
            }
        }
        &.@{tab-prefix-cls}-large {
            height: 48px;
            .ant-tabs-nav{
                .ant-tabs-nav-list{
                    .ant-tabs-tab{
                        padding: 0 16px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .ant-tabs-tab-btn{
                            display: flex;
                            > svg {
                                height: 18px;
                                width: 18px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    
        
        &.@{tab-prefix-cls}-small {
            height: 32px;
            .ant-tabs-nav{
                .ant-tabs-nav-list{
                    .ant-tabs-tab{
                        padding: 0 12px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .ant-tabs-tab-btn{
                            display: flex;
                            > svg {
                                height: 14px;
                                width: 14px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Sizes Tabs */
    &-large {
        .ant-tabs-nav{
            .ant-tabs-nav-list{
                .ant-tabs-tab{
                    .ant-tabs-tab-btn{
                        > svg {
                            height: 21px;
                            width: 21px;
                            margin-bottom: -6px;
                        }
                    }
                }
            }
        }
    }

    
    &-small {
        .ant-tabs-nav{
            .ant-tabs-nav-list{
                .ant-tabs-tab{
                    .ant-tabs-tab-btn{
                        > svg {
                            height: 18px;
                            width: 18px;
                            margin-bottom: -5px;
                        }
                    }
                }
            }
        }
    }
}

.ant-tabs-tabpane{
    .page-content-wrapper{
        > .main-row{
            > .ant-col{
                > .@{card-prefix-cls}{
                    > .ant-card-body{
                        padding: 0px;
                    }
                }
            }
        }
    }
}