@font-face {
  font-family: "Droid";
  src: local("Droid"),
    url(../assets/fonts/Droid-Naskh-Regular.ttf) format("truetype");
}

:root {
  --primary-color: #09c3ff;

  /* // main colors */
  --secondary-color: #324e8f;

  /* // Body colors */
  --body-background: #fff;
  --text-color: #000000;
  --text-color-secondary: #92a6bf;

  /* // Border colors */
  --border-color-base: #dbe7f1;
  --border-color-split: #f5f8ff;

  /* // Body colors */
  --heading-color: #000000;
  --background-color-light: #f5f8ff;

  /* // Header & Sidebar Background */
  --layout-header-background: #fff;

  /* // Border colors */

  /* // Components Background */
  --component-background: #fff;
}

body {
  --font-family: "Cairo", sans-serif;
  --primary-color: #09c3ff;

  /* // main colors */
  --secondary-color: #324e8f;

  /* // Body colors */
  --body-background: #fff;
  --text-color: #000000;
  --text-color-secondary: #92a6bf;

  /* // Border colors */
  --border-color-base: #dbe7f1;
  --border-color-split: #f5f8ff;

  /* // Body colors */
  --heading-color: #000000;
  --background-color-light: #f5f8ff;

  /* // Header & Sidebar Background */
  --layout-header-background: #fff;

  /* // Border colors */

  /* // Components Background */
  --component-background: #fff;
}

body.dark {
  --primary-color: #09c3ff;
  --secondary-color: #00193f;

  /* // Body colors */
  --body-background: #00193f;
  --heading-color: #ffffff;
  --text-color: #ffffff;
  --text-color-secondary: #9cc6e4;
  --background-color-light: #082c58;

  /* // Header & Sidebar Background */
  --layout-header-background: #00193f;

  /* // Border colors */
  --border-color-base: #182f65;
  --border-color-split: #283c6d;

  /* // Components Background */
  --component-background: #00193f;
}
