.@{list-prefix-cls} {
    &-item {
        &-split {
            background-color: @list-item-border-color;
        }
        &-action {
            & > li {
                > a {
                    color: @text-color-secondary;
                    &:hover,
                    &:focus {
                        color: @primary-color;
                    }
                }
                svg {
                    font-size: 1.2em;
                    margin-top: -2px;
                }
            }
        }
        &-meta {
            &-avatar {
                margin-right: @list-item-meta-avatar-margin-right;
                >svg{
                    font-size: 1.35rem;
                    margin-top: -2px;
                    color: @text-color-secondary;
                }
            }
        }
    }
    &-split &-item {
        border-bottom: 1px solid @list-item-border-color;
    }

    &-split &-header {
        border-bottom: 1px solid @list-item-border-color;
    }

    &-split&-empty &-footer {
        border-top: 1px solid @list-item-border-color;
    }

    &-split&-something-after-last-item
        .@{ant-prefix}-spin-container
        > &-items
        > &-item:last-child {
        border-bottom: 1px solid @list-item-border-color;
    }
}
