/* Layout */
.layout {
  min-height: 100vh;
  min-width: 100%;
}

/* Header */

.header {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 0 10px;
  position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  z-index: 999;
  .logo {
    width: 208px;
    flex-shrink: 0;
    > img {
      max-height: 50px;
      max-width: 115px;
    }
  }
  .search-input {
    width: 100%;
  }
  .user-actions {
    flex-shrink: 0;
  }
}

// .ant-menu-item-group-title

/* Sidebar */

.sidebar {
  overflow: auto;
  height: calc(100% - 64px);
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
  border-top: 2px solid @border-color-split;
  z-index: 999;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .user-area {
    margin: 16px;
    padding: 16px 0;
    border-top: 1px solid @border-color-split;
    .user-dropdown {
      margin-bottom: 16px;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
  .ant-menu {
    background-color: transparent;
    height: 100%;
  }
}

[dir="rtl"] {
  .sidebar {
    right: 0;
    left: auto;
  }
}

/* Main Content */

.main-content {
  transition: all 0.2s ease 0s;
  margin-left: 240px;
  .page-content {
    margin: 64px 16px 24px;
    overflow: initial;
    .page-card {
      background-color: transparent;
      padding: 0;
      > .ant-card-body {
        padding: 0;
      }
    }
  }
}

[dir="rtl"] {
  .main-content {
    margin-left: 0;
    margin-right: 240px;
  }
}

@media (max-width: 991px) {
  .main-content {
    margin-left: 0;
  }
  [dir="rtl"] {
    .main-content {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.auth-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .ant-space-item {
    width: 100% !important;
  }
}

.w-100 {
  width: 100% !important;
}

.otp-input {
  width: 40px;
  text-align: center;
  height: 40px;
  margin: 10px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  display: inline-block;
  min-width: 0px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.5715;
  background-color: rgb(245, 248, 255);
  background-image: none;
  border: 1px solid;
  border-radius: 4px;
  transition: all 0.3s ease 0s;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

// .className {

//   .ant-notification-notice-icon {
//     svg {
//       color : inherit !important
//     }
//   }
// }

.ant-drawer-body {
  overflow-y: auto;
}

.ant-badge-count {
  z-index: 10 !important;
}

.table-total {
  color: @text-color-secondary;
}

.page-content-wrapper {
  position: relative;
  &.is-open {
    margin-right: @side-menu-width;
    &.md {
      margin-right: @side-menu-width * 2;
      .side-page-drawer {
        width: @side-menu-width * 2;
      }
    }
    &.lg {
      margin-right: @side-menu-width * 2.5;
      .side-page-drawer {
        width: @side-menu-width * 2.5;
      }
    }
    &.no-side-menu {
      margin: 0;
    }
  }
}

.side-page-drawer {
  position: fixed;
  right: 0;
  top: 64px;
  width: @side-menu-width;
  height: 100%;
  background-color: @layout-sider-background;
  z-index: 7;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  &.is-opened {
    transform: translateX(0);
  }
  .content-side-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    overflow-x: hidden;
    height: calc(100% - 64px);
  }
  .handler-icon {
    position: absolute;
    transform: translateX(-100%);
    top: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    background-color: @layout-sider-background;
    .icon {
      padding: 0.2rem;
      font-size: 1.8rem;
    }
  }
}

[dir="rtl"] {
  .side-page-drawer {
    left: 0;
    right: auto;
    transform: translateX(-100%);
    &.is-opened {
      transform: translateX(0);
    }
    .handler-icon {
      transform: translateX(100%);
    }
  }
  .page-content-wrapper {
    &.is-open {
      margin-right: 0;
      margin-left: @side-menu-width;
      &.md {
        margin-right: 0;
        margin-left: @side-menu-width * 2;
      }
      &.lg {
        margin-right: 0;
        margin-left: @side-menu-width * 2.5;
      }
      &.no-side-menu {
        margin: 0;
      }
    }
  }
}

.menu-collapsed {
  margin-left: 80px !important;
}

[dir="rtl"] .menu-collapsed {
  margin-right: 80px !important;
  margin-left: 0 !important;
}

@media (max-width: 991px) {
  .menu-collapsed {
    margin-left: 0 !important;
  }

  [dir="rtl"] .menu-collapsed {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.ant-layout-sider-collapsed {
  .user-area {
    display: none;
  }
  .ant-menu-item-group-title {
    span {
      display: none;
    }
  }
}

/* Classroom */

// Layout

.layout-classroom {
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    h4 {
      font-weight: bold;
      font-size: 1rem;
    }
    .header-actions {
      display: flex;
      align-items: center;
    }
  }
  .sidebar {
    left: auto;
    right: 0;
    height: calc(100vh - 176px);
    overflow: hidden;
    .ant-layout-sider-children {
      justify-content: flex-start;
    }
  }
  .main-content {
    margin-right: 340px;
    margin-left: 0;
    .page-content {
      margin: 88px 24px 24px;
      background-color: #fff;
      padding: 16px;
      border: 1px dashed @dark-1;
      position: relative;
    }
  }
  .footer {
    background-color: #fff;
    padding: 21px 0;
    position: relative;
    z-index: 1;
    .content-actions {
      width: calc(100% - 340px);
    }
    .sider-actions {
      width: 340px;
    }
  }
}

// Cam

.cam {
  background-color: #000;
  > * {
    object-fit: contain;
  }
  .name {
    background-color: rgba(0, 0, 0, 0.6);
    color: @primary-color;
    height: auto;
    width: auto;
    padding: 3px 6px;
    font-size: 0.7rem;
    > span {
      color: #fff;
    }
  }
}

// Users Collapse

.users-collapse {
  .ant-collapse-header {
    border-bottom: 1px solid @border-color-base;
    border-radius: 0 !important;
  }
}

// Documents Tab

.documents-tab {
  .tab-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      width: 50px;
      text-align: center;
      &:first-child {
        width: calc(100% - 100px);
        text-align: initial;
      }
    }
  }
}

// Document Item

.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 0;
  > div {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    svg {
      font-size: 1.2rem;
      margin-right: 4px;
    }
  }
  > span {
    width: 50px;
    text-align: center;
  }
}

// Tab Item

.tab-item {
  height: calc(100% - 190px);
}

// Tab Title

.tab-title {
  padding: 12px 16px;
  border-bottom: 1px solid @border-color-base;
}

// Tab Content

.tab-content {
  height: calc(100% - 48px);
}

// Chat Item

.chat-tab {
  .tab-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100% - 107px);
  }
}

.chat-form {
  display: flex;
  align-items: center;
  padding: 12px 16px 6px;
  border-top: 1px solid @border-color-base;
  .chat-input {
    position: relative;
    flex: 1;
    input {
      border-radius: 100px;
      padding-right: 40px;
      + .ant-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .ant-btn-sm.ant-btn-icon-only > svg {
    height: 20px;
    width: 20px;
  }
}

.chat-item {
  padding: 0 16px;
  &.right {
    direction: rtl;
    .ant-comment-avatar {
      margin-left: 12px;
      margin-right: 0;
    }
    .chat-item-message {
      background-color: @blue-6;
      color: #fff;
      text-align: left;
    }
  }
  .chat-item-message {
    background-color: @background-color-light;
    padding: 5px 15px;
    border-radius: 20px;
    width: auto;
    display: table;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

[dir="rtl"] {
  .chat-item {
    .ant-comment-avatar {
      margin-left: 12px;
      margin-right: 0;
    }
    &.right {
      direction: ltr;
      .ant-comment-avatar {
        margin-left: 0;
        margin-right: 12px;
      }
      .chat-item-message {
        text-align: right;
      }
    }
  }
}

// Board

.board-pagination {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .board-pagination-buttons {
    display: flex;
    align-items: center;
    .ant-btn {
      border-radius: 50% !important;
    }
    > .ant-btn {
      box-shadow: 0px 0px 10px rgb(0 0 0 / 10%) !important;
    }
    > .ant-btn-text {
      background-color: #fff !important;
      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.018) !important;
      }
    }
    .board-pagination-numbers {
      display: flex;
      align-items: center;
      background-color: #fff;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
      border-radius: 100px;
      padding: 4px;
      margin: 0 16px;
    }
  }
  .board-pages {
    display: flex;
    align-items: center;
    > div {
      width: 60px;
      height: 50px;
      margin: 20px 10px 0;
      background-color: @background-color-light;
      border: 1px solid transparent;
      &.active {
        border-color: @primary-color;
      }
    }
  }
}

.board-controls {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  .ant-btn {
    border-radius: 50% !important;
  }
  > .ant-btn {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%) !important;
  }
  > .ant-btn-text {
    background-color: #fff !important;
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.018) !important;
    }
  }
  .board-controls-buttons {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    border-radius: 100px;
    padding: 4px;
    margin: 0 16px;
  }
}

.board-files {
  position: absolute;
  bottom: 30px;
  left: 30px;
  .ant-btn {
    border-radius: 50% !important;
  }
  > .ant-btn {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%) !important;
  }
  > .ant-btn-text {
    background-color: #fff !important;
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.018) !important;
    }
  }
}

.c-progress {
  display: flex;
  border-radius: 15px;
  .c-progress__item {
    .c-progress__bar {
      width: 100%;
    }
    &:first-child {
      .c-progress__bar {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &:last-child {
      .c-progress__bar {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

[dir="rtl"] {
  .c-progress {
    .c-progress__item {
      &:first-child {
        .c-progress__bar {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      &:last-child {
        .c-progress__bar {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    font-family: @font-family;
  }
}

.course-selector {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  .course-checkbox {
    margin-top: 5px;
  }
  .course-title {
    font-size: 1.1rem;
    font-weight: bold;
    white-space: nowrap;
  }
  .name-desc {
    font-size: 0.8rem;
    opacity: 0.8;
    font-weight: 400;
  }
  .credit-count {
    margin-top: 2rem;
  }
  .course-row {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    &.disabled {
      opacity: 0.5;
    }
  }
  .grade {
    min-width: 200px;
  }
  label {
    p {
      font-weight: bold;
    }
    span {
      color: @menu-item-color;
    }
  }
  .course-area {
    width: 100%;
    border-right: 1px solid @menu-item-color;
    padding-right: 1rem;
  }
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .line {
    margin: 2rem 0;
  }
  .sub-count {
    font-size: 2rem;
    margin-bottom: 0;
    border: 1px solid @primary-color;
    padding: 0.2rem 1rem;
    color: @primary-color;
  }
}

[dir="rtl"] {
  .course-area {
    border-right: none;
    padding-right: 0;
    border-left: 1px solid @menu-item-color;
    padding-left: 1rem;
  }
}

.notification-list-item {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid @border-color-base;
  .ant-list-item-meta-avatar {
    margin: 0;
    font-size: 1.2rem;
  }
  .ant-list-item-meta-title {
    margin: 0;
    font-weight: bold;
  }
  &.not-seen-not-read {
    background-color: darken(@body-background, 5%);
  }
  &.seen-not-read {
    background-color: darken(@body-background, 10%);
  }
  &.seen-read {
    background-color: transparent;
  }
}

// rich text editor
.quill {
  .ql-snow {
    .ql-tooltip {
      background-color: var(--secondary-color);
      color: @menu-item-color;
    }
  }
  .ql-picker {
    color: @menu-item-color;
    .ql-picker-options {
      background-color: var(--secondary-color);
    }
  }
  .ql-toolbar.ql-snow {
    font-family: var(--font-family) !important;
    button:hover,
    button:hover,
    button:focus,
    button:focus,
    button.ql-active,
    button.ql-active,
    .ql-picker-label:hover,
    .ql-picker-label:hover,
    .ql-picker-label.ql-active,
    .ql-picker-label.ql-active,
    .ql-picker-item:hover,
    .ql-picker-item:hover,
    .ql-picker-item.ql-selected,
    .ql-picker-item.ql-selected {
      color: @primary-color;
      .ql-fill {
        stroke: @primary-color;
      }
      .ql-stroke {
        stroke: @primary-color;
      }
    }
    .ql-fill {
      stroke: @menu-item-color;
    }
    .ql-stroke {
      stroke: @menu-item-color;
    }
  }
}

[dir="rtl"] {
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: auto;
    left: 0;
  }
  .ql-editor {
    direction: rtl;
    text-align: right;
  }

  .ql-editor ol,
  .ql-editor ul {
    padding-right: 1.5em;
    padding-left: 0;
    li {
      padding-right: 1.5em;
      padding-left: 0 !important;
    }
  }
  .ql-editor li:not(.ql-direction-rtl)::before {
    margin-right: -1.5em;
    margin-left: 0.3em;
  }
}

@media (max-width: 991px) {
  .ant-tooltip.ant-menu-inline-collapsed-tooltip {
    display: none;
  }
}

.protal-page-content-wrapper {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
