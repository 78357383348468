.@{dialog-prefix-cls} {
  .@{dialog-prefix-cls}-body {
    .main-row {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
    .page-content-wrapper {
      > .main-row {
        > .ant-col {
          > .@{card-prefix-cls} {
            > .ant-card-body {
              padding: 0px;
            }
          }
        }
      }
    }
  }
}
