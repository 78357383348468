.@{ant-prefix}-input {
    &-prefix,
    &-suffix {
        color: @input-icon-color;
        font-size: 1.2em;
        margin-top: -2px;
    }
}

.ant-input-number-affix-wrapper {
    width: 100%;
  }