@buttons: primary, secondary, success,
    warning, danger, info, light,dark;

.getButtons(@index:1) when(@index <= length(@buttons)) {
    @name: extract(extract(@buttons, @index), 1);
    @color: ~'@{@{name}-color}';
    @color-light: fade(color(@color), 15%);

    .@{btn-prefix-cls}{
        // button style
        &-@{name} {
            .button-variant-primary(#fff, @color , ~"@{color}-hover", ~"@{color}-active");
            & when (@name = light) {
                .button-variant-primary(@text-color-secondary, @color , ~"@{color}-hover", ~"@{color}-active");
            }
        }

        // text button style
        &-text&-@{name}{
            .button-variant-other( @color, transparent, transparent);
            box-shadow: none;
            text-shadow: none;

            &:hover,
            &:focus {
                & when (@theme = dark) {
                    
                    .button-color(~`colorPalette("@{color}", 7) `; @btn-text-hover-bg; transparent);
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 5) `; @btn-text-hover-bg; transparent);
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-hover"; @btn-text-hover-bg; transparent);
                }
            }

            &:active {
                & when (@theme = dark) {
                    .button-color(~`colorPalette("@{color}", 5) `; fadein(@btn-text-hover-bg, 1%); transparent);
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 7) `; fadein(@btn-text-hover-bg, 1%); transparent);
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-active"; fadein(@btn-text-hover-bg, 1%); transparent);
                }
            }
            .button-disabled(@disabled-color; transparent; transparent);
        }

        // default button style
        &-default&-@{name}{
            .button-color(@color, @btn-default-bg, @color);
            &:hover,
            &:focus {
                & when (@theme = dark) {
                    .button-color(
                ~`colorPalette("@{color}", 7) `; @btn-default-bg; ~`colorPalette("@{color}", 7)
                    `
                );
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(
                ~`colorPalette("@{color}", 5) `; @btn-default-bg; ~`colorPalette("@{color}", 5)
                    `
                );
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-hover", @btn-default-bg, ~"@{color}-hover");
                }
            }

            &:active {
                & when (@theme = dark) {
                    .button-color(
                ~`colorPalette("@{color}", 5) `; @btn-default-bg; ~`colorPalette("@{color}", 5)
                    `
                );
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(
                ~`colorPalette("@{color}", 7) `; @btn-default-bg; ~`colorPalette("@{color}", 7)
                    `
                );
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-active", @btn-default-bg, ~"@{color}-active");
                }
            }
            .button-disabled();
        }

        // link button style
        &-link&-@{name}{
            .button-variant-other(@color, transparent, transparent);
            box-shadow: none;
        
            &:hover,
            &:focus {
                & when (@theme = dark) {
                    .button-color(~`colorPalette("@{color}", 7) `; transparent; transparent);
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 5) `; transparent; transparent);
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-hover"; transparent; transparent);
                }
            }
        
            &:active {
                & when (@theme = dark) {
                    .button-color(~`colorPalette("@{color}", 5) `; transparent; transparent);
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 7) `; transparent; transparent);
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-active"; transparent; transparent);
                }
            }
            .button-disabled(@disabled-color; transparent; transparent);
        }

        // dashed button style
        &-dashed&-@{name}{
            .button-variant-other(@color, transparent, @color);
            box-shadow: none;

            &:hover,
            &:focus {
                & when (@theme = dark) {
                    .button-color(~`colorPalette("@{color}", 7) `; transparent; ~`colorPalette("@{color}", 7) `);
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 5) `; transparent; ~`colorPalette("@{color}", 5) `);
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-hover"; transparent; ~"@{color}-hover");
                }
            }
        
            &:active {
                & when (@theme = dark) {
                    .button-color(~`colorPalette("@{color}", 5) `; transparent; ~`colorPalette("@{color}", 5) `);
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 7) `; transparent; ~`colorPalette("@{color}", 7) `);
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-active"; transparent; ~"@{color}-active");
                }
            }
            .button-disabled(@disabled-color; transparent; transparent);
        }
        
        
        // dashed button style
        &-flush&-@{name}{
            .button-color(@color, fade(color(@color), 10%), transparent);
            & when (@name = light) {
                .button-color(@text-color-secondary, fade(color(@color), 90%), transparent);
            }
            box-shadow: none;
            text-shadow: none;
            &:hover,
            &:focus {
                & when (@theme = dark) {
                    .button-color(@color, fade(color(@color), 15%), transparent);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, fade(color(@color), 95%), transparent);
                    }
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(@color, fade(color(@color), 15%), transparent);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, fade(color(@color), 95%), transparent);
                    }
                }
                & when (@theme = variable) {
                    .button-color(@color, fade(color(@color), 15%), transparent);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, fade(color(@color), 95%), transparent);
                    }
                }
            }

            &:active {
                & when (@theme = dark) {
                    .button-color(@color, fade(color(@color), 20%), transparent);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, fade(color(@color), 100%), transparent);
                    }
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(@color, fade(color(@color), 20%), transparent);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, fade(color(@color), 100%), transparent);
                    }
                }
                & when (@theme = variable) {
                    .button-color(@color, fade(color(@color), 20%), transparent);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, fade(color(@color), 100%), transparent);
                    }
                }
            }
            .button-disabled();
        }

        // dashed button style
        &-bordered&-@{name}{
            .button-variant-other(@color, transparent, @color);
            & when (@name = light) {
                .button-color(@text-color-secondary, transparent, @text-color-secondary);
            }
            box-shadow: none;
            text-shadow: none;

            &:hover,
            &:focus {
                & when (@theme = dark) {
                    .button-color(~`colorPalette("@{color}", 7) `; transparent; ~`colorPalette("@{color}", 7) `);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, transparent, @text-color-secondary);
                    }
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 5) `; transparent; ~`colorPalette("@{color}", 5) `);
                    & when (@name = light) {
                        .button-color(@text-color-secondary, transparent, @text-color-secondary);
                    }
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-hover"; transparent; ~"@{color}-hover");
                    & when (@name = light) {
                        .button-color(@text-color-secondary, transparent, @text-color-secondary);
                    }
                }
            }
        
            &:active {
                & when (@theme = dark) {
                    .button-color(~`colorPalette("@{color}", 5) `; transparent; ~`colorPalette("@{color}", 5) `);
                    & when (@name = light) {
                        .button-color(fade(color(@dark-color), 60%), transparent, fade(color(@dark-color), 60%));
                    }
                }
                & when (not (@theme = dark) and not (@theme = variable)) {
                    .button-color(~`colorPalette("@{color}", 7) `; transparent; ~`colorPalette("@{color}", 7) `);
                    & when (@name = light) {
                        .button-color(fade(color(@dark-color), 60%), transparent, fade(color(@dark-color), 60%));
                    }
                }
                & when (@theme = variable) {
                    .button-color(~"@{color}-active"; transparent; ~"@{color}-active");
                    & when (@name = light) {
                        .button-color(fade(color(@dark-color), 60%), transparent, fade(color(@dark-color), 60%));
                    }
                }
            }
            .button-disabled(@disabled-color; transparent; transparent);
        }

        
    }
    .getButtons(@index + 1);
}


.getButtons();


/* Icon Buttons */

.@{btn-prefix-cls}{
    >span + svg{
        margin: 0 0 -4px 6px;
    }
    > svg{
        font-size: 1.2em;
        height: 18px;
        margin: 0 6px -4px 0;
    }
    &-icon-only{
        display: flex;
        justify-content: center;
        align-items: center;
        >svg{
            height: 24px;
            width: 24px;
            margin: 0 0 -2px 0;
        }
    }
    &-lg&-icon-only{
        >svg{
            height: 30px;
            width: 30px;
        }
    }
    &-sm&-icon-only{
        >svg{
            height: 18px;
            width: 18px;
        }
    }
    &.ant-input-search-button{
        > svg{
            margin: 0 0 -4px 0;
        }
    }
}


[dir="rtl"]{
    .@{btn-prefix-cls}{
        >span + svg{
            margin: 0 6px -4px 0;
        }
        > svg{
            margin: 0 0 -4px 6px;
        }
        &-icon-only{
            >svg{
                margin: 0 0 -2px 0;
            }
        }
        &.ant-input-search-button{
            > svg{
                margin: 0 0 -4px 0;
            }
        }
    }
}